import React from 'react';
import './App.css';
import DragAndDropMediaPlayer from './components/DragAndDropMediaPlayer';
import FileBabyLargeLogoSection from "./components/FileBabyLargeLogoSection";
function App() {
    return (
        <div className="App">
            <header className="App-header">
                <h1>Media Player with Content Credentials</h1>
            </header>
            <main>

                <DragAndDropMediaPlayer />
                <FileBabyLargeLogoSection />
            </main>
        </div>
    );
}

export default App;
