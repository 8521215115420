import React from 'react';
import fileBabyLogo from '../img/logo_myfilebaby.png';
import ManifestRetriever from './ManifestRetriever';

const FileBabyLargeLogoSection = () => (
    <div className="file-baby-logo-section">
        <div className="image-container filebabylarge">
            <div>Claim Your Files at File.Baby</div>
            <a href={"https://file.baby"}><img src={fileBabyLogo} alt="File Baby Large Logo" /></a>
            <ManifestRetriever fileName="logo_myfilebaby.png" filePath={fileBabyLogo} />
        </div>
    </div>
);

export default FileBabyLargeLogoSection;
