import React, { useState, useRef, useEffect } from 'react';
import '../styles/controlstyles.css';
import crLogo from '../img/cr_logo.png'; // Ensure the correct path to the logo

const DragAndDropMediaPlayer = ({ purchasedFile }) => {
    const [files, setFiles] = useState(purchasedFile ? [purchasedFile] : []);
    const [currentFileIndex, setCurrentFileIndex] = useState(0);
    const [manifest, setManifest] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [isManifestVisible, setIsManifestVisible] = useState(false);
    const fileInputRef = useRef(null);
    const audioRef = useRef(null);
    const videoRef = useRef(null);
    const imageRef = useRef(null);

    useEffect(() => {
        if (files.length > 0) {
            const file = files[currentFileIndex];
            setManifest(null); // Clear previous manifest
            setError(''); // Clear previous error
            checkContentCredentials(file);
            const objectURL = URL.createObjectURL(file);

            // Stop any currently playing media
            if (audioRef.current) audioRef.current.pause();
            if (videoRef.current) videoRef.current.pause();

            if (isAudioFile(file)) {
                audioRef.current.src = objectURL;
                audioRef.current.style.display = 'block';
                videoRef.current.style.display = 'none';
                imageRef.current.style.display = 'none';
                audioRef.current.play().catch(err => console.error('Error playing the audio:', err));
            } else if (isVideoFile(file)) {
                videoRef.current.src = objectURL;
                videoRef.current.style.display = 'block';
                audioRef.current.style.display = 'none';
                imageRef.current.style.display = 'none';
                videoRef.current.play().catch(err => console.error('Error playing the video:', err));
            } else if (isImageFile(file)) {
                imageRef.current.src = objectURL;
                imageRef.current.style.display = 'block';
                audioRef.current.style.display = 'none';
                videoRef.current.style.display = 'none';
            }
        }
    }, [currentFileIndex, files]);

    const handleDrop = (event) => {
        event.preventDefault();
        const droppedFiles = Array.from(event.dataTransfer.files);
        setFiles(prevFiles => [...prevFiles, ...droppedFiles]);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const isAudioFile = (file) => ['audio/mpeg', 'audio/wav', 'audio/ogg'].includes(file.type);
    const isVideoFile = (file) => ['video/mp4', 'video/webm', 'video/ogg'].includes(file.type);
    const isImageFile = (file) => ['image/jpeg', 'image/png', 'image/gif', 'image/webp'].includes(file.type);

    const checkContentCredentials = async (file) => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await fetch('https://paybots-claim-engine.azurewebsites.net/api/manifest', {
                method: 'POST',
                body: formData,
            });

            const result = await response.json();
            if (response.ok) {
                setManifest(result);
                setError(''); // Clear previous error if manifest is found
            } else {
                setManifest(null); // Ensure manifest is cleared if there's an error
                setError('No credentials found for this file.');
            }
        } catch (error) {
            console.error('Error checking content credentials:', error);
            setManifest(null); // Ensure manifest is cleared if there's an error
            setError('No credentials found for this file.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleFileSelect = (event) => {
        const selectedFiles = Array.from(event.target.files);
        setFiles(selectedFiles);
        setCurrentFileIndex(0);
    };

    const handleEnded = () => {
        if (currentFileIndex < files.length - 1) {
            setCurrentFileIndex(currentFileIndex + 1);
        }
    };

    const toggleManifestVisibility = () => {
        setIsManifestVisible(!isManifestVisible);
    };

    return (
        <div className="drag-drop-media-player">
            <div
                className="drop-zone"
                onDrop={handleDrop}
                onDragOver={handleDragOver}
            >
                <p>Drag and drop your files here, or use the Choose Files button.</p>
                <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileSelect}
                    multiple
                />
            </div>
            {files.length > 0 && files.map((file, index) => (
                <p
                    key={index}
                    onClick={() => setCurrentFileIndex(index)}
                    style={{
                        fontWeight: currentFileIndex === index ? 'bold' : 'normal',
                        color: currentFileIndex === index ? 'blue' : 'black',
                    }}
                >
                    {file.name}
                </p>
            ))}
            <audio ref={audioRef} controls onEnded={handleEnded} style={{ display: 'none', marginTop: '20px' }}>
                Your browser does not support the audio element.
            </audio>
            <video ref={videoRef} controls onEnded={handleEnded} style={{ display: 'none', marginTop: '20px', width: '100%' }}>
                Your browser does not support the video element.
            </video>
            <img ref={imageRef} alt="Selected content" style={{ display: 'none', marginTop: '20px', width: '100%' }} />
            <img
                src={crLogo}
                alt="CR Logo"
                width="50"
                style={{ cursor: 'pointer', margin: '20px 0' }}
                onClick={toggleManifestVisibility}
            />
            {isManifestVisible && (
                <div className={"manifest"}>
                    {isLoading && <p className={"loading"}>Loading...</p>}
                    {manifest ? <pre>{JSON.stringify(manifest, null, 2)}</pre> : <p>{error}</p>}
                </div>
            )}
        </div>
    );
};

export default DragAndDropMediaPlayer;
